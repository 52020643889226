// Global packages and components
import { OpenCard, Button } from '~/components'

// Types
import type { BankHoliday, OpeningTimes } from '~/types'

interface BranchCardProps {
  title: string
  slug: string
  openingTimes: OpeningTimes
  region: string
  bankHolidays?: BankHoliday[]
}

// Main export
const BranchCard = ({
  title,
  slug,
  openingTimes,
  region,
  bankHolidays,
}: BranchCardProps) => (
  <div className="rounded-2xl bg-gray-100 p-5">
    <h4 className="text-2xl font-semibold">{title}</h4>
    <div className="mb-4">
      <OpenCard
        openingTimes={openingTimes}
        region={region}
        bankHolidays={bankHolidays}
      />
    </div>
    <Button
      to={`/branches/${slug}`}
      title="Branch details"
      style="tealGreen"
    />
  </div>
)

export default BranchCard
