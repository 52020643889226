// Global packages and components
import { clsx } from 'clsx'
import { useEffect } from 'react'
import { Slider, ProductCard, PortraitBanner, Heading } from '~/components'
import { useProducts } from '~/hooks'

// Types
import type { PortraitBannerProps, SelectOption } from '~/types'

interface BlockLogoCarouselProps {
  title: string
  productCodes: string[]
  addBanner?: boolean
  addFilters?: boolean
  banner?: PortraitBannerProps
  filters?: SelectOption[]
}

// Main export
export default function BlockProductCarousel({
  title,
  productCodes,
  addBanner,
  banner,
}: BlockLogoCarouselProps) {
  const { products, getProducts } = useProducts()
  const hasBanner = addBanner && banner?.image?.asset ? true : false

  // Get the products on mount
  useEffect(() => {
    if (productCodes) {
      getProducts({ productCodes, returnFilters: false })
    }
  }, [])

  return products && products.length !== 0 ? (
    <div className="container">
      {title && (
        <Heading
          className="mb-10 text-center"
          tag="h2"
          size="h4"
        >
          {title}
        </Heading>
      )}
      <div className="lg:flex">
        <div className="hidden lg:block">
          {addBanner && <PortraitBanner {...banner} />}
        </div>
        <div
          className={clsx({
            'lg:w-full': !hasBanner,
            'lg:w-[calc(100%_-_(20rem_+_1.5rem))]': hasBanner,
          })}
        >
          {/* @NOTE: Not currently working in conjunction with the filters, needs discussion */}
          {/* {addFilters && filters && filters.length > 0 && (
            <ProductCarouselFilters filters={filters} />
          )} */}
          <Slider
            className="w-full"
            perPage={1}
            arrows
            arrowsInline
            dots={false}
            options={{
              perMove: 1,
              autoWidth: true,
              gap: '1.5rem',
            }}
          >
            {products.map((e, i) => (
              <div
                key={i}
                className="w-80"
              >
                <ProductCard {...e} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  ) : null
}
