// Global packages or components
import groq from 'groq'
import { createClient } from '@sanity/client'

// Types
import type { SanityProps, SanityFetchProps } from '~/types'

// Sanity client init
export const sanity = ({ projectId, dataset }: SanityProps) => {
  return createClient({
    projectId,
    dataset,
    apiVersion: '2023-02-01',
    useCdn: true,
  })
}

// Fetch function helper
export const sanityFetch = async ({
  projectId,
  dataset,
  query,
}: SanityFetchProps) => {
  const groQuery = groq`${query}`

  return await sanity({ projectId, dataset }).fetch(groQuery)
}

// Queries
export { default as getSite } from './queries/getSite'
export { default as getFixedPage } from './queries/getFixedPage'
export { default as getPage } from './queries/getPage'
export { default as getFaqCategories } from './queries/getFaqCategories'
export { default as getFaqs } from './queries/getFaqs'
export { default as getBranch } from './queries/getBranch'
export { default as getBranches } from './queries/getBranches'
export { default as getCategory } from './queries/getCategory'
export { default as getBlogs } from './queries/getBlogs'
export { default as getAllProductsPage } from './queries/getAllProductsPage'
export { default as getBuy } from './queries/getBuy'
export { default as getHire } from './queries/getHire'
export { default as getProductEnrichment } from './queries/getProductEnrichment'
export { default as getBlogPost } from './queries/getBlogPost'

// Groq fields
export * from './groq'

// Types
export * from '~/types/sanity'
