// Global packages and components
import { clsx } from 'clsx'
import { Link } from '@remix-run/react'
import { Button, ContentEditor } from '~/components'
import { backgroundColourClass, getLinkType } from '~/utils'

// Types
import type { PortableTextBlock } from '@portabletext/react'
import type { SanityButtonProps, SanityCustomImage } from '~/types'

interface BlockBannerNarrowProps {
  image?: SanityCustomImage
  content?: PortableTextBlock[]
  button?: SanityButtonProps
  flip?: boolean
  extendImage?: boolean
  backgroundColor?: string
}

// Main export
export default function BlockBannerNarrow({
  image,
  content,
  flip = false,
  extendImage = false,
  button,
  backgroundColor = 'transparent',
}: BlockBannerNarrowProps = {}) {
  return (
    <div
      className={clsx('container', {
        'pt-12': extendImage,
      })}
    >
      <Link
        to={getLinkType({
          linkType: button?.linkType,
          linkReference: button?.linkReference,
          linkSlug: button?.linkSlug,
          linkExternal: button?.linkExternal,
        })}
        className={clsx('group/wrapper block rounded-xl', {
          [backgroundColourClass(backgroundColor)]: backgroundColor,
        })}
      >
        <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-8">
          <div
            className={clsx('order-2 flex flex-col justify-center py-8', {
              'px-8 lg:pr-0 xl:order-1': !flip,
              'px-8 lg:pl-0 xl:order-2': flip,
            })}
          >
            <div className="mb-6">
              {content && <ContentEditor content={content} />}
            </div>
            {button && (
              <div>
                <Button
                  {...button}
                  mimic={true}
                />
              </div>
            )}
          </div>
          <div
            className={clsx('order-1 flex justify-center', {
              'xl:order-2': !flip,
              'xl:order-1': flip,
            })}
          >
            {image?.asset?.url && (
              <img
                src={image.asset.url}
                alt={image?.alt}
                className={clsx(
                  'w-full object-contain object-center lg:object-right-bottom',
                  {
                    '-mt-12': extendImage,
                  }
                )}
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}
