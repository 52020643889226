// Types
import type { SanityCustomImage } from '~/types'

interface Props {
  image: SanityCustomImage
  imageMobile?: SanityCustomImage
  classNamePic?: string
  classNameImg?: string
}

// Main export
const Picture = ({ image, imageMobile, classNamePic, classNameImg }: Props) => (
  <picture className={classNamePic}>
    <source
      srcSet={image?.asset?.url}
      media="(min-width: 1024px)"
    />
    <img
      className={classNameImg}
      src={imageMobile?.asset?.url ? imageMobile.asset.url : image?.asset?.url}
      alt={imageMobile?.alt ? imageMobile.alt : image?.alt}
    />
  </picture>
)

export default Picture
