// Asset
export const asset = `asset->{url}`

// Link ref
export const linkRef = `linkReference->{title, slug}`

// Image
export const image = `image{alt, asset->{url}}`

// Rich text editor
export const textEditor = `..., markDefs[]{..., _type == "internalLink" => {"slug": @.reference->slug, "type": @.reference->type, "author": @.reference->author}}, _type == "image" => {..., ${asset}}`

// Button
export const button = `..., ${linkRef}`

// Nav item object
export const navItem = `..., linkReference->{title, slug, icon{alt, ${asset}}, type}`

// Banner sidebar object
export const bannerSidebar = `bannerSidebar{..., content[]{${textEditor}}, ${image}, button{${button}}}`

// Product listing common fields
export const productListing = `content[]{${textEditor}}, banner{..., content[]{${textEditor}}, ${image}}, ${bannerSidebar}, subCategories{categories[]->{..., parent->{slug}, ${image}}}`

// SEO fields
export const seo = `seo{..., shareImage{..., ${asset}}, redirect{..., page->{slug, title}}}`
