// Types
import type { SanityCustomImage } from '~/types'

interface Props {
  image?: SanityCustomImage
}

// Main export
export default function BlockImageInline({ image }: Props) {
  return (
    <div className="container">
      <img
        src={image?.asset?.url}
        alt={image?.alt}
        className="h-auto w-full rounded-2xl"
      />
    </div>
  )
}
