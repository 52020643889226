// Global packages and components
import { Link } from '@remix-run/react'
import { Slider, Heading } from '~/components'

// Types
import type { CustomCategoryProps } from '~/types'

interface BlockCategoryCarouselProps {
  title: string
  items: CustomCategoryProps[]
}

// Main export
export default function BlockCategoryCarousel({
  title,
  items,
}: BlockCategoryCarouselProps) {
  return (
    <div className="container">
      {title && (
        <Heading
          className="mb-10 text-center"
          tag="h2"
          size="h4"
        >
          {title}
        </Heading>
      )}
      {items && items.length !== 0 && (
        <Slider
          perPage={2}
          options={{
            gap: '1rem',
            breakpoints: {
              768: {
                perPage: 3,
                gap: '2rem',
              },
              1024: {
                perPage: 4,
                gap: '2rem',
              },
              1280: {
                perPage: 5,
                gap: '2rem',
              },
              1536: {
                perPage: 6,
                gap: '2rem',
              },
            },
          }}
        >
          {items.map((e, i) => (
            <Link
              to={`/${e?.type}/c/${e?.slug?.current}`}
              key={i}
              className="group"
            >
              {e?.icon?.asset?.url && (
                <img
                  src={e.icon.asset.url}
                  alt={e.icon?.alt}
                  className="mx-auto mb-3 block transition-transform group-hover:scale-95"
                />
              )}
              {e?.title && (
                <h5 className="text-center font-semibold">{e.title}</h5>
              )}
            </Link>
          ))}
        </Slider>
      )}
    </div>
  )
}
