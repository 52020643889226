// Global packages and functions
import { useState } from 'react'
import { useRouteLoaderData } from '@remix-run/react'
import { getBranches } from '~/sanity'
import { haversineDistance } from '~/utils/data'

// Types
import type { BranchesListerDataProps, RootDataProps } from '~/types'

// Main export
const useGetBranches = () => {
  const { env } = useRouteLoaderData('root') as RootDataProps
  const [branches, setBranches] = useState<BranchesListerDataProps[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchBranches = async (location: {
    latitude: number
    longitude: number
  }) => {
    setLoading(true)

    await getBranches({
      dataset: env.ENVIRONMENT,
      projectId: env.SANITY_PROJECT,
    })
      .then((data: BranchesListerDataProps[]) => {
        if (location.latitude && location.longitude) {
          const branchesWithDistance = data.map(branch => ({
            ...branch,
            distance: haversineDistance(
              location.latitude,
              location.longitude,
              branch.location.latitude,
              branch.location.longitude
            ),
          }))
          setBranches(
            branchesWithDistance
              .sort((a, b) => a.distance - b.distance)
              .slice(0, 6)
          )
        }
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { branches, loading, setLoading, fetchBranches }
}

export default useGetBranches
