// Global packages and components
import { useEffect, useState } from 'react'
import { useRouteLoaderData } from '@remix-run/react'
import { getBlogs } from '~/sanity'

// Types
import type { BlogListingItem, BlogListerProps, RootDataProps } from '~/types'

interface Props {
  offset: number
  conceptId?: string
}

// Main export
const useBlogPosts = ({ offset = 9, conceptId }: Props) => {
  const { env } = useRouteLoaderData('root') as RootDataProps
  const [posts, setPosts] = useState<BlogListingItem[]>([])
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  const fetchBlogs = async (offset: number) => {
    await getBlogs({
      dataset: env.ENVIRONMENT,
      projectId: env.SANITY_PROJECT,
      offset: offset,
      conceptId: conceptId,
    })
      .then((data: BlogListerProps) => {
        setPosts(data.blogs ?? [])
        setCount(data.count ?? 0)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchBlogs(offset)
  }, [offset])

  return { posts, count, loading }
}

export default useBlogPosts
