// Global packages or components
import { sanityFetch, groqBranchs } from '~/sanity'

// Types
import type { BranchesListerDataProps, SanityProps } from '~/types'

// Main export
const getBranches = async ({
  dataset,
  projectId,
}: SanityProps): Promise<BranchesListerDataProps[]> => {
  return await sanityFetch({
    query: `*[_type == "branches"]${groqBranchs}`,
    dataset: dataset,
    projectId: projectId,
  })
}

export default getBranches
