// Global packages and components
import { clsx } from 'clsx'
import {
  ContentEditor,
  Button,
  ProductSearch,
  Picture,
  VideoBackground,
} from '~/components'
import { backgroundColourClass } from '~/utils'

// Types
import type { PortableTextBlock } from '@portabletext/react'
import type {
  SanityButtonProps,
  SanityCustomFile,
  SanityCustomImage,
} from '~/types'

interface Props {
  mediaType: 'image' | 'video'
  images?: {
    image: SanityCustomImage
    imageMobile?: SanityCustomImage
  }
  videos?: {
    video?: SanityCustomFile
    videoMobile?: SanityCustomFile
  }
  content?: PortableTextBlock[]
  button?: SanityButtonProps
  roundedCorners?: boolean
  addSearch?: boolean
  blockBackgroundColor?: string
}

// Main export
export default function BlockBanner({
  mediaType,
  images,
  videos,
  content,
  button,
  roundedCorners,
  addSearch,
  blockBackgroundColor = 'transparent',
}: Props) {
  return (
    <div className="container">
      <div
        className={clsx({
          [backgroundColourClass(blockBackgroundColor)]: blockBackgroundColor,
          'rounded-xl p-5 lg:p-10':
            blockBackgroundColor && blockBackgroundColor !== 'transparent',
        })}
      >
        <div
          className={clsx('relative rounded-2xl md:aspect-banner', {
            'md:rounded-[25rem]': roundedCorners,
          })}
        >
          <div className="relative z-[2] w-full px-5 py-20 text-center text-white md:absolute md:left-0 md:top-0 md:flex md:h-full md:flex-col md:items-center md:justify-center md:px-8">
            <div className="max-w-6xl">
              {content && <ContentEditor content={content} />}
              {addSearch && (
                <div className="mx-auto pt-6 text-neutral-900 md:max-w-[75%]">
                  <ProductSearch
                    featured
                    buttonText="Search"
                    placeholder="What are you looking for?"
                  />
                </div>
              )}
              {button && (
                <div className="pt-6">
                  <Button {...button} />
                </div>
              )}
            </div>
            <div className="max-w-3xl"></div>
          </div>
          {mediaType == 'video' && videos?.video ? (
            <VideoBackground
              video={videos.video}
              videoMobile={videos?.videoMobile}
              className="absolute left-0 top-0 z-[1] size-full overflow-hidden rounded-2xl"
            />
          ) : mediaType == 'image' && images?.image ? (
            <Picture
              image={images.image}
              imageMobile={images?.imageMobile}
              classNamePic="absolute left-0 top-0 z-[1] size-full overflow-hidden rounded-2xl bg-black/30"
              classNameImg="size-full object-cover mix-blend-multiply"
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
