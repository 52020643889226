// Global packages and components
import { Slider, Heading } from '~/components'

// Types
import type { SanityCustomImage } from '~/types'

interface BlockLogoCarouselProps {
  title: string
  items: {
    image: SanityCustomImage
    title: string
    slug: string
  }[]
}

// Main export
export default function BlockLogoCarousel({
  title,
  items,
}: BlockLogoCarouselProps) {
  return items && items.length !== 0 ? (
    <div className="container">
      {title && (
        <Heading
          className="mb-10 text-center"
          tag="h2"
          size="h4"
        >
          {title}
        </Heading>
      )}
      <Slider
        className="-mx-2"
        perPage={2}
        dots={true}
        options={{
          breakpoints: {
            768: {
              perPage: 3,
            },
            1024: {
              perPage: 4,
            },
            1280: {
              perPage: 5,
            },
            1536: {
              perPage: 6,
            },
          },
        }}
      >
        {items.map((e, i) => (
          <div
            className="px-2"
            key={i}
          >
            <div className="flex aspect-square items-center justify-center rounded-2xl bg-white p-4 md:p-5">
              {e?.image?.asset?.url && (
                <img
                  src={e.image.asset.url}
                  alt={e.image?.alt}
                  className="max-w-full"
                />
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ) : null
}
