import { clsx } from 'clsx'
import type {
  SanityCustomFile,
  VideoBlockRatios,
  VideoBlockMobileRatios,
  VideoBlockTypes,
} from '~/types'

export const videoClasses = ({
  videoRatio,
  mobileVideoRatio,
  videoType,
  mobileFile,
  mobileOembed,
}: {
  videoRatio?: VideoBlockRatios
  mobileVideoRatio?: VideoBlockMobileRatios
  videoType: VideoBlockTypes
  mobileFile?: SanityCustomFile
  mobileOembed?: string
}) => {
  const isFileOrEmbedMissing =
    (videoType === 'file' && !mobileFile?.asset.url) ||
    (videoType === 'oembed' && !mobileOembed)

  return clsx('container', {
    // Desktop aspect ratios
    'md:aspect-square': videoRatio === '1/1',
    'md:aspect-video': videoRatio === '16/9',
    'md:aspect-wide-screen': videoRatio === '21/9',
    'md:aspect-tv': videoRatio === '4/3',
    'md:aspect-auto': videoRatio === 'auto' || videoRatio === undefined,

    // Mobile aspect ratios
    'aspect-square': mobileVideoRatio === '1/1' || isFileOrEmbedMissing,
    'aspect-wide-screen': mobileVideoRatio === '21/9' || isFileOrEmbedMissing,
    'aspect-tv': mobileVideoRatio === '4/3' || isFileOrEmbedMissing,
    'aspect-portrait': mobileVideoRatio === 'portrait' || isFileOrEmbedMissing,
    'aspect-auto':
      mobileVideoRatio === 'auto' ||
      mobileVideoRatio === undefined ||
      isFileOrEmbedMissing,
  })
}
