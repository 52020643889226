// Global packages and functions
import { useEffect, useState } from 'react'
import { useRouteLoaderData } from '@remix-run/react'
import { getFaqCategories, getFaqs } from '~/sanity'

// Types
import type { FaqItem, RootDataProps, SanityFaqTaxonomyScheme } from '~/types'

// Main export
const useFaqs = () => {
  const { env } = useRouteLoaderData('root') as RootDataProps
  const [activeCategory, setActiveCategory] = useState<string | null>(null)
  const [allFaqs, setAllFaqs] = useState<FaqItem[] | null>(null)
  const [faqs, setFaqs] = useState<FaqItem[] | null>(null)
  const [categories, setCategories] = useState<
    SanityFaqTaxonomyScheme['concepts'] | null
  >(null)

  // On mounted fetch categories and all faqs
  useEffect(() => {
    const fetchCategories = async () => {
      const scheme = await getFaqCategories({
        dataset: env.ENVIRONMENT,
        projectId: env.SANITY_PROJECT,
      })

      setCategories(scheme?.concepts ?? [])
    }

    const fetchFaqs = async () => {
      const faqs = await getFaqs({
        dataset: env.ENVIRONMENT,
        projectId: env.SANITY_PROJECT,
      })

      setAllFaqs(faqs ?? [])
    }

    fetchCategories()
    fetchFaqs()
  }, [])

  // When categories fetched, set the first item as active
  useEffect(() => {
    if (categories && categories.length > 0) {
      setActiveCategory(categories[0].id)
    }
  }, [categories])

  // When active category is set/changed, filter all faqs and return only applicable
  // @NOTE: Chosen to do this instead of GROQ calls on every category change for speed purposes.
  // ...if the number of FAQs gets insane we could obviously change it, but unlikely.
  useEffect(() => {
    if (activeCategory && allFaqs) {
      const filtered = allFaqs.filter(e =>
        e?.categories?.some(e => e._ref == activeCategory)
      )

      setFaqs(filtered)
    }
  }, [activeCategory])

  return {
    faqs,
    categories,
    activeCategory,
    setActiveCategory,
  }
}

export default useFaqs
