// Global packages and components
import { clsx } from 'clsx'
import { SanityCustomFile } from '~/types'

// Types
interface Props {
  video: SanityCustomFile
  videoMobile?: SanityCustomFile
  className?: string
}

// Main export
const VideoBackground = ({ video, videoMobile, className }: Props) => (
  <div className={className ?? 'relative'}>
    <video
      className={clsx('absolute left-0 top-0 size-full object-cover', {
        'hidden lg:block': videoMobile?.asset?.url,
      })}
      preload="auto"
      autoPlay
      muted
      loop
      controls={false}
      playsInline
    >
      <source
        src={video.asset.url}
        type="video/mp4"
      />
    </video>
    {videoMobile && (
      <video
        className="absolute left-0 top-0 block size-full object-cover lg:hidden"
        preload="auto"
        autoPlay
        muted
        loop
        controls={false}
        playsInline
      >
        <source
          src={video.asset.url}
          type="video/mp4"
        />
      </video>
    )}
  </div>
)

export default VideoBackground
