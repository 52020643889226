// Global packages or components
import { sanityFetch } from '~/sanity'

// Types
import type { SanityProps, SanityFaqTaxonomyScheme } from '~/types'

// Main export
const getFaqCategories = async ({
  dataset,
  projectId,
}: SanityProps): Promise<SanityFaqTaxonomyScheme> => {
  return await sanityFetch({
    query: `*[_type == "skosConceptScheme" && schemeId == "a61ae2"][0]{concepts[]->{"title" : prefLabel, "id": _id}}`,
    dataset: dataset,
    projectId: projectId,
  })
}

export default getFaqCategories
