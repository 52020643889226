// Global packages and components
import { clsx } from 'clsx'
import { BannerDefault, Heading } from '~/components'

// Types
import type { CardColumnProps } from '~/types'

interface BlockCardColumnsProps {
  title?: string
  variation?: '5050' | '7030' | '3070' | '3ColNarrow'
  columns?: {
    rows: CardColumnProps[]
  }[]
}

// Main export
export default function BlockCardColumns({
  title,
  columns,
  variation,
}: BlockCardColumnsProps) {
  return (
    <div className="container">
      {title && (
        <Heading
          className="mb-10 text-center"
          tag="h2"
          size="h4"
        >
          {title}
        </Heading>
      )}
      <div
        className={clsx('grid grid-cols-1 gap-6 md:gap-8 xl:gap-10', {
          'md:grid-cols-12': columns?.length == 2,
          'md:grid-cols-3': columns?.length == 3,
        })}
      >
        {columns?.map((column, ci) => (
          <div
            key={ci}
            className={clsx('flex flex-col', {
              'gap-6 md:gap-8 xl:gap-10': column?.rows?.length > 1,
              'md:col-span-5 lg:col-span-4':
                (columns?.length == 2 && ci == 0 && variation == '3070') ||
                (columns?.length == 2 && ci == 1 && variation == '7030'),
              'md:col-span-6':
                columns?.length == 2 &&
                (variation == '5050' || variation == undefined),
              'md:col-span-7 lg:col-span-8':
                (columns?.length == 2 && ci == 0 && variation == '7030') ||
                (columns?.length == 2 && ci == 1 && variation == '3070'),
            })}
          >
            {column?.rows?.map((row, ri) => (
              <BannerDefault
                key={ri}
                {...row}
                reduceContentWidth={
                  variation == '5050' ||
                  (variation == '7030' && ci == 0) ||
                  (variation == '3070' && ci == 1)
                    ? true
                    : false
                }
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
