// Global packages and components
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { ContentEditor, Button, Heading } from '~/components'

// Types
import type { SanityButtonProps } from '~/types'
import type { PortableTextBlock } from '@portabletext/react'

interface BlockContentTabsProps {
  title: string
  tabs: {
    title: string
    content?: PortableTextBlock[]
    button?: SanityButtonProps
  }[]
}

// Main export
export default function BlockContentTabs({
  title,
  tabs,
}: BlockContentTabsProps) {
  return (
    <div className="container">
      {title && (
        <Heading
          className="mb-10 text-center"
          tag="h2"
          size="h4"
        >
          {title}
        </Heading>
      )}
      {tabs && tabs.length > 0 && (
        <TabGroup>
          <TabList className="mb-10 flex flex-wrap items-center justify-center">
            {tabs.map((e, i) => (
              <Tab
                key={'tab-' + i}
                className="mx-1 mb-2 rounded-full border border-teal-green px-5 py-2 font-semibold data-[selected]:bg-teal-green data-[selected]:text-white lg:mx-2"
              >
                {e.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="mx-auto max-w-4xl text-center">
            {tabs.map((e, i) => (
              <TabPanel key={'tabpanel' + i}>
                {e?.content && <ContentEditor content={e.content} />}
                {e?.button && (
                  <div className="pt-6">
                    <Button {...e.button} />
                  </div>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      )}
    </div>
  )
}
