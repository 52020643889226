// Global packages and components
import { clsx } from 'clsx'
import { ContentEditor, Trustpilot } from '~/components'

// Types
import type { PortableTextBlock } from '@portabletext/react'

interface BlockTrustPilotProps {
  /**
   * Rich text editor content json
   */
  content?: PortableTextBlock[]
  alignment?: 'left' | 'center' | 'right'
  template: 'carousel' | 'mini'
}

// Main export
export default function BlockTrustPilot({
  content,
  alignment = 'center',
  template = 'carousel',
}: BlockTrustPilotProps) {
  return (
    <div className="container">
      <div
        className={clsx('mb-12', {
          'text-left': alignment == 'left',
          'text-center': alignment == 'center',
          'text-right': alignment == 'right',
        })}
      >
        <ContentEditor content={content} />
      </div>
      {template && <Trustpilot templateId={template} />}
    </div>
  )
}
