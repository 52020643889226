// Global packages and components
import { clsx } from 'clsx'
import { ContentEditor, Loader, Accordion, Heading } from '~/components'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useFaqs } from '~/hooks'

// Types
import type { PortableTextBlock } from '@portabletext/react'

interface FaqsBlockProps {
  content?: PortableTextBlock[]
}

// Main export
export default function BlockFaqs({ content }: FaqsBlockProps) {
  const { faqs, categories, activeCategory, setActiveCategory } = useFaqs()

  return (
    <div className="container">
      {content && (
        <div className="mb-10">
          <ContentEditor content={content} />
        </div>
      )}
      <div className="grid grid-cols-12 gap-6 lg:gap-8 xl:gap-10">
        <div className="col-span-12 md:col-span-4">
          <div className="min-h-[400px] rounded-2xl bg-gray-100 p-6 lg:p-8">
            {!categories ? (
              <div className="flex h-full items-center justify-center">
                <Loader className="size-10 text-gray-900" />
              </div>
            ) : categories.length > 0 ? (
              <>
                <Heading
                  tag="h2"
                  size="h5"
                >
                  Topics
                </Heading>
                <ul>
                  {categories.map((e, i) => (
                    <li key={i}>
                      <button
                        onClick={() => setActiveCategory(e.id)}
                        className={clsx(
                          'flex w-full justify-between py-4 text-left',
                          {
                            'border-b-2 border-teal-green font-semibold text-teal-green':
                              activeCategory == e.id,
                            'border-b border-neutral-200':
                              activeCategory !== e.id,
                          }
                        )}
                      >
                        {e.title}
                        <ChevronRightIcon className="size-4" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <p>No categories found</p>
            )}
          </div>
        </div>
        <div className="col-span-12 md:col-span-8">
          {!faqs ? (
            <div className="flex justify-center">
              <Loader className="size-10 text-gray-900" />
            </div>
          ) : faqs.length > 0 ? (
            <div>
              <Accordion>
                {faqs.map((e, i) => (
                  <Accordion.Section
                    title={e.question}
                    className="mb-2"
                    key={i}
                  >
                    <div>
                      {e.answer && <ContentEditor content={e.answer} />}
                    </div>
                  </Accordion.Section>
                ))}
              </Accordion>
            </div>
          ) : (
            <p>No faqs found</p>
          )}
        </div>
      </div>
    </div>
  )
}
