// Global packages and components
import { Button, ContentEditor } from '~/components'
import { PortraitBannerProps } from '~/types'

// Main export
const PortraitBanner = ({
  content,
  image,
  button,
}: PortraitBannerProps = {}) => (
  <div className="mx-auto mb-8 grid w-full shrink-0 grid-cols-2 gap-5 rounded-xl bg-emerald-green p-5 sm:w-4/5 md:w-3/5 lg:mx-0 lg:mb-0 lg:mr-6 lg:w-80 lg:grid-cols-1">
    {content && (
      <div className="text-white">
        <ContentEditor content={content} />
      </div>
    )}
    {image?.asset?.url && (
      <img
        src={image?.asset?.url}
        alt={image?.alt}
        className="row-span-2 m-auto"
      />
    )}
    <div className="mr-auto mt-auto">{button && <Button {...button} />}</div>
  </div>
)

export default PortraitBanner
