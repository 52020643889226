// Global packages or components
import { sanityFetch } from '~/sanity'
import { groqBlogLister } from '~/sanity/groq'

// Types
import type { BlogListerProps, SanityProps } from '~/types'

interface GetBlogsProps extends SanityProps {
  offset: number
  conceptId?: string
}

// Main export
const getBlogs = async ({
  dataset,
  projectId,
  offset = 9,
  conceptId,
}: GetBlogsProps): Promise<BlogListerProps> => {
  return await sanityFetch({
    query: groqBlogLister({ offset, conceptId }),
    dataset: dataset,
    projectId: projectId,
  })
}

export default getBlogs
