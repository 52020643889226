// Global packages and components
import { useState } from 'react'
import { BlogListerItem, Button, InlineLoader } from '~/components'
import { useBlogPosts } from '~/hooks'

// Types
import type { SanityConceptItem } from '~/types'

interface Props {
  category?: SanityConceptItem
}

// Main export
export default function BlockBlogListing({ category }: Props = {}) {
  const [offset, setOffset] = useState<number>(9)
  const { posts, count, loading } = useBlogPosts({
    offset,
    conceptId: category?._id,
  })

  return (
    <div className="container">
      {loading ? (
        <InlineLoader>
          <p className="font-semibold">Fetching posts</p>
        </InlineLoader>
      ) : posts && posts && count > 0 ? (
        <>
          <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-2 lg:gap-10 xl:grid-cols-3">
            {posts.map((e, i) => (
              <BlogListerItem
                key={i}
                {...e}
              />
            ))}
          </div>
          <div className="text-center">
            {count > offset && (
              <div className="mb-4">
                <Button
                  title="Load more"
                  style="tealGreen"
                  onClick={() => setOffset(offset + 9)}
                  minWidth
                />
              </div>
            )}
            <p className="text-xs text-neutral-500">
              Showing&nbsp;
              <span>{count && offset > count ? count : offset}</span>
              &nbsp;of&nbsp;<span>{count}</span>&nbsp;entries
            </p>
          </div>
        </>
      ) : (
        <p className="pt-8 text-center text-2xl">No posts found</p>
      )}
    </div>
  )
}
