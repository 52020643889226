// Global packages and components
import { clsx } from 'clsx'
import { ContentEditor } from '~/components'

// Types
import type { PortableTextBlock } from '@portabletext/react'

interface BlockContentProps {
  /**
   * Rich text editor content json
   */
  content?: PortableTextBlock[]
  alignment?: 'left' | 'center' | 'right'
}

// Main export
export default function BlockContent({
  content,
  alignment = 'left',
}: BlockContentProps) {
  return (
    <div
      className={clsx('container', {
        'text-left': alignment == 'left',
        'text-center': alignment == 'center',
        'text-right': alignment == 'right',
      })}
    >
      <ContentEditor content={content} />
    </div>
  )
}
