// Global packages and components
import { clsx } from 'clsx'
import { ContentEditor } from '~/components'
import { backgroundColourClass } from '~/utils'

// Types
import type { PortableTextBlock } from '@portabletext/react'
import type { SanityCustomImage } from '~/types'

type Props = {
  content?: PortableTextBlock[]
  image?: SanityCustomImage
  backgroundColor?: string
}

// Main export
export default function BlockFormContent({
  content,
  image,
  backgroundColor = 'teal-green',
}: Props) {
  return (
    <div className="container">
      <div
        className={clsx(
          'grid grid-cols-1 gap-4 overflow-hidden rounded-xl md:grid-cols-2',
          {
            [backgroundColourClass(backgroundColor)]: backgroundColor,
          }
        )}
      >
        <div>
          {image?.asset?.url && (
            <img
              src={image.asset.url}
              alt={image?.alt}
              className="size-full rounded-br-[120px] rounded-tr-lg"
            />
          )}
        </div>
        <div className="flex h-full flex-col justify-center">
          {content && (
            <div className="p-8 last:*:mb-0">
              <ContentEditor content={content} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
