// Global packages and components
import { Link } from '@remix-run/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

// Types
import type { CustomCategoryProps } from '~/types'

interface BlockCategoryGridProps {
  items: CustomCategoryProps[]
}

// Main export
export default function BlockCategoryGrid({ items }: BlockCategoryGridProps) {
  return (
    <div className="container">
      <div className="grid grid-cols-2 gap-4 md:gap-8 lg:grid-cols-3">
        {items &&
          items.map((e, i) => (
            <Link
              to={`/${e?.type}/c/${e?.slug?.current}`}
              key={i}
              className="relative aspect-not-square overflow-hidden rounded-2xl"
            >
              {e?.title && (
                <div className="absolute left-0 top-0 z-[2] flex h-full w-full flex-col items-start justify-end px-4 py-3 text-white md:py-5">
                  <div className="flex w-full items-center justify-between">
                    <h4 className="mb-0 text-left text-base font-semibold md:text-xl">
                      {e.title}
                    </h4>
                    <div className="ml-3 hidden size-7 items-center justify-center rounded-full bg-vibrant-green md:flex">
                      <ArrowRightIcon className="size-4 text-forest-green" />
                    </div>
                  </div>
                </div>
              )}
              {e?.image?.asset?.url && (
                <div className="absolute left-0 top-0 z-[1] h-full w-full bg-black/30">
                  <img
                    src={e.image.asset.url}
                    alt={e.image?.alt}
                    className="h-full w-full object-cover mix-blend-multiply"
                  />
                </div>
              )}
            </Link>
          ))}
      </div>
    </div>
  )
}
