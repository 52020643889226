// Global packages or components
import { sanityFetch } from '~/sanity'

// Types
import type { FaqFetchProps, FaqItem } from '~/types'

// Main export
const getFaqs = async ({
  dataset,
  projectId,
  id,
}: FaqFetchProps): Promise<FaqItem[]> => {
  return id
    ? await sanityFetch({
        query: `*[_type == "faqs" && "${id}" in categories[]._ref]`,
        dataset: dataset,
        projectId: projectId,
      })
    : await sanityFetch({
        query: `*[_type == "faqs"]`,
        dataset: dataset,
        projectId: projectId,
      })
}

export default getFaqs
