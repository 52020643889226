// Types
import type { SanityLinkObject, SanityCustomImage } from '~/types'

interface Props {
  columns: {
    image: SanityCustomImage
    link: SanityLinkObject
  }[]
}

// Main export
export default function BlockBannerStrips({ columns }: Props) {
  return (
    <div className="container grid grid-cols-1 gap-6 md:grid-cols-3 lg:gap-8 xl:gap-10">
      {columns?.map((e, i) => (
        <div
          key={i}
          className="relative aspect-[5/2] overflow-hidden rounded-xl"
        >
          {e?.image?.asset?.url && (
            <img
              src={e.image.asset?.url}
              alt={e.image?.alt}
              className="absolute left-0 top-0 size-full object-cover"
            />
          )}
        </div>
      ))}
    </div>
  )
}
