// Global packages and components
import { clsx } from 'clsx'
import { videoClasses } from '~/utils'

// Types
import type {
  SanityCustomImage,
  SanityCustomFile,
  VideoBlockRatios,
  VideoBlockMobileRatios,
  VideoBlockTypes,
} from '~/types'

type VideoProps = {
  videoRatio?: VideoBlockRatios
  mobileVideoRatio?: VideoBlockMobileRatios
  videoType: VideoBlockTypes
  videoTitle?: string
  file?: SanityCustomFile
  mobileFile?: SanityCustomFile
  oembed?: string
  mobileOembed?: string
  poster?: SanityCustomImage
}

// Main export
export default function BlockVideo({
  videoRatio,
  mobileVideoRatio,
  videoType,
  videoTitle,
  file,
  mobileFile,
  oembed,
  mobileOembed,
  poster,
}: VideoProps) {
  return (
    <div
      className={videoClasses({
        videoRatio,
        mobileVideoRatio,
        videoType,
        mobileFile,
        mobileOembed,
      })}
    >
      {videoType == 'file' && file?.asset.url ? (
        <video
          id="videoPlayer"
          controls
          className={clsx('size-full rounded-lg', [
            { 'hidden md:block': mobileFile?.asset.url },
          ])}
          preload="auto"
          poster={poster?.asset.url}
        >
          <track
            default
            kind="captions"
            src=""
          />
          <source
            src={file.asset.url}
            type="video/mp4"
          />
        </video>
      ) : videoType == 'oembed' && oembed ? (
        <iframe
          src={oembed}
          title={videoTitle ?? 'Video'}
          className={clsx('size-full rounded-lg', [
            { 'hidden md:block': mobileOembed },
          ])}
          allow="fullscreen"
          loading="lazy"
          allowFullScreen
        />
      ) : null}
      {videoType == 'file'
        ? mobileFile?.asset.url && (
            <video
              id="videoPlayer"
              controls
              className="size-full rounded-lg md:hidden"
              preload="auto"
              poster={poster?.asset.url}
            >
              <track
                default
                kind="captions"
                src=""
              />
              <source
                src={mobileFile.asset.url}
                type="video/mp4"
              />
            </video>
          )
        : mobileOembed && (
            <iframe
              src={mobileOembed}
              title={videoTitle ?? 'Video'}
              className="size-full rounded-lg md:hidden"
              allow="fullscreen"
              loading="lazy"
              allowFullScreen
            />
          )}
    </div>
  )
}
