// Global packages and components
import { useState } from 'react'
import { useRouteLoaderData } from '@remix-run/react'
import {
  ContentEditor,
  Button,
  BranchCard,
  InlineLoader,
  AddressFinder,
} from '~/components'
import { useGetBranches } from '~/hooks'

// Types
import type { PortableTextBlock } from '@portabletext/react'
import type { AutocompleteAddress } from 'getaddress-api'
import { RootDataProps } from '~/types'

interface BlockBranchFinderProps {
  /**
   * Rich text editor content json
   */
  content?: PortableTextBlock[]
}

// Main export
export default function BlockBranchFinder({ content }: BlockBranchFinderProps) {
  // Hooks
  const { userAddress } = useRouteLoaderData('root') as RootDataProps

  // Local state
  const [address, setAddress] = useState<string>(userAddress?.line ?? '')
  const [addressData, setAddressData] = useState<AutocompleteAddress | null>(
    userAddress?.data ?? null
  )

  // Hooks
  const { branches, loading, fetchBranches } = useGetBranches()

  return (
    <div className="container">
      <div className="border-b border-gray-200 pb-10 lg:pb-14">
        <div className="mb-10">
          <ContentEditor content={content} />
        </div>
        <div className="mb-8 grid md:grid-cols-2">
          <div className="flex gap-5">
            <AddressFinder
              address={address}
              addressData={addressData}
              setAddress={setAddress}
              setAddressData={setAddressData}
            />
            <Button
              title="Find a branch"
              disabled={
                !addressData?.latitude || !addressData?.longitude ? true : false
              }
              onClick={() => {
                if (addressData?.latitude && addressData?.longitude) {
                  fetchBranches({
                    latitude: addressData.latitude,
                    longitude: addressData.longitude,
                  })
                }
              }}
              style="tealGreen"
            />
          </div>
        </div>
        {loading ? (
          <InlineLoader>
            <p className="font-semibold">Fetching branches</p>
          </InlineLoader>
        ) : branches ? (
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {branches.map((branch, key) => (
              <BranchCard
                key={key}
                title={branch.title}
                slug={branch.slug.current}
                region={branch.region}
                openingTimes={branch.openingTimes}
              />
            ))}
          </div>
        ) : (
          <p>No branches found</p>
        )}
      </div>
    </div>
  )
}
