// Global packages and components
import { clsx } from 'clsx'
import { Link } from '@remix-run/react'
import {
  ContentEditor,
  BannerImage,
  Button,
  VideoBackground,
} from '~/components'
import { getLinkType } from '~/utils'

// Types
import type { CardColumnProps } from '~/types'

interface Props extends CardColumnProps {
  reduceContentWidth?: boolean
}

// Inner content
const BannerInner = ({
  mediaType,
  content,
  image,
  video,
  button,
  buttonType,
  alignment,
  reduceContentWidth,
}: Props) => (
  <>
    <div
      className={clsx('relative z-[2] flex size-full flex-col p-5 xl:p-8', {
        'justify-start': alignment == 'top',
        'justify-center': alignment == 'middle',
        'justify-end': alignment == 'bottom',
        'justify-between': alignment == 'between',
        'bg-neutral-150': !image?.asset?.url,
      })}
    >
      {content && (
        <div
          className={clsx('last:*:mb-0', {
            'md:w-11/12 lg:w-10/12 xl:w-3/4':
              reduceContentWidth && image?.asset?.url,
            'mb-6': button && buttonType !== 'hide',
            'text-white': image?.asset?.url,
          })}
        >
          <ContentEditor content={content} />
        </div>
      )}
      {button && buttonType !== 'hide' && buttonType !== 'none' && (
        <div>
          <Button
            {...button}
            mimic={buttonType == 'wrap' ? true : false}
          />
        </div>
      )}
    </div>
    {mediaType == 'video' && video ? (
      <VideoBackground
        video={video}
        className={clsx(
          'left-0 top-0 h-full w-full overflow-hidden rounded-2xl',
          {
            absolute: content,
            relative: !content,
          }
        )}
      />
    ) : mediaType == 'image' && image ? (
      <BannerImage
        image={image}
        absolute={content ? true : false}
      />
    ) : null}
  </>
)

// Main export
const BannerDefault = ({
  mediaType,
  content,
  image,
  video,
  button,
  buttonType,
  alignment = 'middle',
  aspectRatio = 'full',
  reduceContentWidth = false,
}: Props) => {
  const classes = clsx('relative block overflow-hidden rounded-2xl md:h-full', {
    'md:aspect-[7/5]': aspectRatio == 'full' || aspectRatio == 'half',
    'group/wrapper': buttonType !== 'inline',
  })

  return buttonType == 'inline' || buttonType == 'none' ? (
    <div className={classes}>
      <BannerInner
        mediaType={mediaType}
        video={video}
        content={content}
        image={image}
        button={button}
        buttonType={buttonType}
        alignment={alignment}
        aspectRatio={aspectRatio}
        reduceContentWidth={reduceContentWidth}
      />
    </div>
  ) : (
    <Link
      to={getLinkType({
        linkType: button?.linkType,
        linkReference: button?.linkReference,
        linkSlug: button?.linkSlug,
        linkExternal: button?.linkExternal,
      })}
      className={classes}
    >
      <BannerInner
        mediaType={mediaType}
        video={video}
        content={content}
        image={image}
        button={button}
        buttonType={buttonType}
        alignment={alignment}
        aspectRatio={aspectRatio}
        reduceContentWidth={reduceContentWidth}
      />
    </Link>
  )
}

export default BannerDefault
