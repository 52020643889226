// Global packages and components
import { clsx } from 'clsx'
import {
  BlockContent,
  BlockCardColumns,
  BlockCategoryCarousel,
  BlockLogoCarousel,
  BlockBanner,
  BlockContentTabs,
  BlockLetterboxBanner,
  BlockPostCarousel,
  BlockCategoryGrid,
  BlockFaqs,
  BlockProductCarousel,
  BlockBranchFinder,
  BlockBannerNarrow,
  BlockBlogListing,
  BlockVideo,
  BlockFormContent,
  BlockBannerStrips,
  BlockImageInline,
  BlockTrustpilot,
} from '~/components'
import {
  marginTopDesktop,
  marginBottomDesktop,
  paddingTopDesktop,
  paddingBottomDesktop,
  marginTopMobile,
  marginBottomMobile,
  paddingTopMobile,
  paddingBottomMobile,
  backgroundColourClass,
} from '~/utils'

// Types
type BlockProps = {
  name: string
  _type: string
  data: any
  options: {
    paddingTop: number
    paddingBottom: number
    marginTop: number
    marginBottom: number
    paddingTopMob: number
    paddingBottomMob: number
    marginTopMob: number
    marginBottomMob: number
    backgroundColor: string
    hasVisibilityAnimation: boolean
  }
}

type BlocksProps = {
  blocks: BlockProps[]
}

// Main export
export default function Blocks({ blocks }: BlocksProps) {
  if (!blocks || blocks?.length == 0) return null

  return blocks.map((item: BlockProps, index: number) => {
    const name = item?.name ? item.name : item?._type ? item._type : ''
    const options = item?.options

    const {
      marginTop,
      marginBottom,
      paddingTop,
      paddingBottom,
      marginTopMob,
      marginBottomMob,
      paddingTopMob,
      paddingBottomMob,
      backgroundColor,
      hasVisibilityAnimation,
    } = options

    const classes = clsx(`page-block relative page-block--${name}`, {
      [marginTopDesktop(marginTop)]: marginTop,
      [marginBottomDesktop(marginBottom)]: marginBottom,
      [paddingTopDesktop(paddingTop)]: paddingTop,
      [paddingBottomDesktop(paddingBottom)]: paddingBottom,
      [marginTopMobile(marginTopMob)]: marginTopMob,
      [marginBottomMobile(marginBottomMob)]: marginBottomMob,
      [paddingTopMobile(paddingTopMob)]: paddingTopMob,
      [paddingBottomMobile(paddingBottomMob)]: paddingBottomMob,
      [backgroundColourClass(backgroundColor)]: backgroundColor,
      'page-block--has-animation': hasVisibilityAnimation,
    })

    const blockProps = {
      ...item?.data,
    }

    return (
      <div
        key={name + '-' + index}
        className={classes}
      >
        {name == 'content' ? (
          <BlockContent {...blockProps} />
        ) : name == 'cardColumns' ? (
          <BlockCardColumns {...blockProps} />
        ) : name == 'categoryCarousel' ? (
          <BlockCategoryCarousel {...blockProps} />
        ) : name == 'logoCarousel' ? (
          <BlockLogoCarousel {...blockProps} />
        ) : name == 'banner' ? (
          <BlockBanner {...blockProps} />
        ) : name == 'contentTabs' ? (
          <BlockContentTabs {...blockProps} />
        ) : name == 'letterboxBanner' ? (
          <BlockLetterboxBanner {...blockProps} />
        ) : name == 'postCarousel' ? (
          <BlockPostCarousel {...blockProps} />
        ) : name == 'categoryGrid' ? (
          <BlockCategoryGrid {...blockProps} />
        ) : name == 'faqsBlock' ? (
          <BlockFaqs {...blockProps} />
        ) : name == 'productCarousel' ? (
          <BlockProductCarousel {...blockProps} />
        ) : name == 'branchFinder' ? (
          <BlockBranchFinder {...blockProps} />
        ) : name == 'bannerNarrow' ? (
          <BlockBannerNarrow {...blockProps} />
        ) : name == 'blogs' ? (
          <BlockBlogListing {...blockProps} />
        ) : name == 'video' ? (
          <BlockVideo {...blockProps} />
        ) : name == 'formContent' ? (
          <BlockFormContent {...blockProps} />
        ) : name == 'bannerStrips' ? (
          <BlockBannerStrips {...blockProps} />
        ) : name == 'imageInline' ? (
          <BlockImageInline {...blockProps} />
        ) : name == 'trustpilot' ? (
          <BlockTrustpilot {...blockProps} />
        ) : null}
      </div>
    )
  })
}
