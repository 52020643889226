// Global packages and components
import { ContentEditor, BannerImage } from '~/components'

// Types
import type { PortableTextBlock } from '@portabletext/react'
import type { SanityButtonProps, SanityCustomImage } from '~/types'

interface BlockLetterboxBannerProps {
  image?: SanityCustomImage
  content?: PortableTextBlock[]
  button?: SanityButtonProps
  roundedCorners?: boolean
}

// Main export
export default function BlockLetterboxBanner({
  image,
  content,
}: BlockLetterboxBannerProps) {
  return (
    <div className="container">
      <div className="relative overflow-hidden rounded-2xl">
        {content && (
          <div className="relative z-[3] flex min-h-60 items-center justify-center py-10 text-center text-white">
            <div>
              <ContentEditor content={content} />
            </div>
          </div>
        )}
        {image?.asset?.url && <BannerImage image={image} />}
      </div>
    </div>
  )
}
