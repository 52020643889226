import {
  navItem,
  image,
  asset,
  textEditor,
  bannerSidebar,
  productListing,
  seo,
} from './common'
import { flexContent } from './blocks'

// Global fields
export const groqGlobal = `{..., logo{..., ${asset}}, cardImages{..., images[]{..., ${asset}}}}`

// Settings fields
export const groqSettings = `{..., branchesPage->{title, slug}, shareImage{..., ${asset}}}`

// Navigation
export const groqNavigation = `{..., items[]{${navItem}, childItems[]{${navItem}, childItems[]{${navItem}, childItems[]{${navItem}}}}, featuredItems[]{..., ${image}, "slug": page->slug.current}}}`

// Home
export const groqHome = `{..., ${flexContent}}`

// Pages
export const groqPage = `{..., ${seo}, ${flexContent}}`

// Branches
export const groqBranch = `{..., ${seo}, content[]{${textEditor}}}`

// Branches
export const groqBranchs = `{..., seo{..., shareImage{..., ${asset}}}}`

// Blog Lister
export const groqBlogLister = ({
  offset = 9,
  conceptId,
}: {
  offset: number
  conceptId?: string
}) => {
  if (conceptId) {
    return `{"blogs": *[_type == "blog" && "${conceptId}" in categories[]._ref] | order(_createdAt asc)[0...${offset}]{..., ${image}}, "count": count(*[_type == "blog" && "${conceptId}" in categories[]._ref])}`
  } else {
    return `{"blogs": *[_type == "blog"] | order(_createdAt asc)[0...${offset}]{..., ${image}}, "count": count(*[_type == "blog"])}`
  }
}

// blog single
export const groqBlogSingle = `{..., ${seo}, ${flexContent}, ${image}, ${bannerSidebar}, categories[]->{..., pageReference->{title, slug}}, fullWidthImage{alt, asset->{url}}, relatedPosts[]->{title, slug, excerpt, ${image}}}`

// Categories
export const groqCategories = `{..., ${seo}, ${flexContent}, ${image}, content[]{${textEditor}}, icon{..., ${asset}}, ${productListing}, attributeFilters{..., options[]{..., icon{..., ${asset}}}}, parent->{..., slug, title, ${asset}}}`

// All products page
export const groqAllProducts = `{..., ${seo}, ${productListing}}`

// All products page
export const groqHireBuy = `{..., ${seo}, ${flexContent}, ${productListing}}`

// Product enrichment
export const groqProductEnrichment = `{faqs[]->{question, answer}, blogPosts[]->{slug, title, excerpt, ${image}}}`
